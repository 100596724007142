// $font-size-base: 0.95rem !default;

@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Patua+One&display=swap");
@import "../../node_modules/bootstrap/scss/bootstrap";

$sidebar-bg-color: #0c1e35;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: #b3b8d4;
$highlight-color: #dee2ec;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;
$page-background-colour: #eff2f6;
$accent-colour: #f8ad17;

@import "~react-pro-sidebar/dist/scss/styles.scss";


// Typography
.navbar-brand, h1, h2, h3, h4, h5, h6 {
  font-family: 'Patua One', cursive;
}

.pro-sidebar {
  z-index: 1021;
  box-shadow: none;

  @include media-breakpoint-up(md) {
    & {
      box-shadow: #26394d 2px 0px 21px -8px;
    }
  }

  a.selectedMenu {
    color: $accent-colour !important;
  }

  > .pro-sidebar-inner {
    background-color: #252c2f;

    > .pro-sidebar-layout .pro-sidebar-header {
      background-color: $accent-colour;
    }
  }

  .pro-menu-item svg {
    font-size: 18px;
    position: relative;
    top: -1px;
  }

  .pro-menu-item,
  .pro-menu-item a {
    color: #e1e1e1;
  }

  span.pro-icon-wrapper svg > path {
    stroke: white;
  }

  .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item a {
    padding-left: 30px;
    font-size: 13px;
    color: #8294a8;
  }
}

body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: "Roboto", sans-serif;
  background-color: $page-background-colour;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .hamburgerSmall {
    display: none;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .sidebar-btn {
      transition: width 0.3s;
      width: 150px;
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;

      span {
        margin-left: 5px;
        font-size: 13px;
      }

      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }

    .userInfo {
      display: None;
    }

    .menuHeaderContent {
      padding: 15px;

      img {
        width: 100%;
        height: auto;
        margin-bottom: 0 !important;
      }
    }
  }

  .pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
    background-color: #252c2f
  } 

  .pro-sidebar .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item a:hover {
    color: $accent-colour;
  }

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .content {
      padding: 16px;
    }

    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;

      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;

      a {
        text-decoration: none;
        color: #888;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .social-bagdes {
    margin-top: 10px;

    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;

    > span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header {
      h1 {
        transform: translateX(20px);
      }
    }

    .block {
      direction: rtl;

      > span {
        margin-left: 0;
        margin-right: 10px;
      }
    }

    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;

    header {
      h1 {
        font-size: 24px;
      }
    }
  }

  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

    .hamburgerSmall {
      display: block;
    }

    .hamburger {
      display: none;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;

  &.red {
    color: #ffffff;
    background: #d63030;
  }

  &.gray {
    color: #ffffff;
    background: #777777;
  }

  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

#mainNav {
  // box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  box-shadow: rgb(38, 57, 77) 0px 2px 17px -10px;
}

.fixedPageHeader {
  z-index: 1019;
  top: 53px;
  background-color: $page-background-colour;
}

.menuHeaderContent {
  padding: 14px;
  font-size: 13px;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;

  img {
    max-width: 100px;
  }
  .react-datepicker {
    .react-datepicker__header {
      background-color: #f8ad17;
    }
  }
}

.tooltip {
  pointer-events: none;
}

// Table Formatting

table.fixed {
  table-layout: fixed;
  td, th {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

table.compact {
  font-size: 0.9rem;
}

tr.inactiveAuthor td {
  color: orangered !important;
}

// National Page Archive
#npaSidebar {
  .form-label {
    font-size: 0.9rem;
    margin-bottom: 3px;
  }
  .accordion-body {
    padding:0;
    background-color: rgb(231, 233, 235);
  }
  .accordion-item {
    border:none;
  }
  .accordion-button {
    color:var(--bs-body-color);
    background-color: rgb(231, 233, 235);
    border:none;
    box-shadow: none;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 20px;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
  .npaSideBarWrapper {
    display: flex; 
    flex-direction: column; 
    height: 100%;
  }
  .npaSideBarContent {
    display: block; 
    flex-grow: 1;
    overflow-y: auto; 
    padding: 15px;
  }
}
