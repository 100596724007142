@import "../../node_modules/bootstrap/scss/bootstrap";

#pages,
#summary {
    padding-top: 15px;
}

#pages .pageWrapper:nth-child(even) {
    padding-left: 0;
}

#pages .pageWrapper:nth-child(odd):not(.dpeTitle) {
    padding-right: 0;
}

.pageWrapper div.page {
    border: 1px solid #ccc;
}

.pageWrapper:nth-of-type(odd):not(.backPage) div.page {
    border-right: none;
}

.pageWrapper:nth-of-type(even):not(.frontPage) div.page {
    border-left: none;
}

.pageWrapper {
    display: flex;
    flex-direction: column;
    place-content: space-between;

    &.notInEdition {
        opacity: 0.3;

        .page:hover {
            box-shadow: none;
            -webkit-box-shadow: none;
        }

    }

    &.legalNotice {
        .page:hover {
            box-shadow: none;
            -webkit-box-shadow: none;
        }
    }
}

.outputSummary {

    &.hideComplete {
        .complete {
            display: none;
        }
    }

    .pageWrapper {
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer;
    }

    .pageInner {

        padding: 7px;
        display: flex;
        flex-direction: column;
        place-content: space-between;
        flex-grow: 1;
        border-radius: 5px;
        transition: box-shadow 0.5s;
        box-shadow: none;

        &:hover {
            -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
            box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
            z-index: 100;

            .page {
                box-shadow: none;
            }
        }

        &.complete {
            background-color: #9bcf9b;
        }

        &.missing {
            background-color: #c99797;
        }

        &.missing_from_chp {
            background-color: #d3ca0c6e;
        }
    }

}

.dpeTitle {
    padding-right: calc(var(--bs-gutter-x) * 0.25) !important;
    padding-left: calc(var(--bs-gutter-x) * 0.25) !important;

    .dpeBlock {
        background-color: rgb(33, 37, 41);
        color: #ccc;
        padding: 10px;
    }
}

@media screen and (min-width: 2220px) {
    .col-xxxl-1 {
        flex: 0 0 auto !important;
        width: 8.33333333% !important;
    }
}

@media screen and (min-width: 992px) {
    select#pubDpe {
        min-width: 250px;
    }
}

.pageInfoCompact {
    display: none;
}

.outputSummary {

    &.showPageSuite {
        .noPageSuite {
            display: none;
        }
    }

    .versionedEdition {
        display: none;
    }
}

.compactView {
    #pages {

        .pageInfo {
            display: none;
        }

        .pageInfoCompact {
            display: block;
        }

        // .pageWrapper,
        // .dpeTitle {
        //     @extend .col-xxl-1;
        // }
    }
}

.thumbnailView {
    #pages {
        .pageInfo {
            display: none;
        }

        .pageWrapper,
        .dpeTitle {
            @extend .col-xxl-1;
        }
    }
}

.pageInfo,
.pageInfoCompact {
    .pageInfoRow {
        font-size: 0.75rem
    }

    .pageInfoRow:nth-child(odd) {
        background: rgba(0, 0, 0, 0.05);
    }

}


.page,
.summaryItem {
    transition: box-shadow 0.5s;
    box-shadow: none;
}

.page:hover,
.summaryItem:hover {
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
    z-index: 100;
}

html,
body,
#pages,
#summary {
    background-color: #f3f2f2;
}

.page {
    background-color: white;
}

.scooterHeadshot {
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
    transition: filter 0.5s;
    width: 125px;
}

// Thumbnails
.lightbox {
    // display: none;
    position: absolute;
    z-index: 2000;
    left: 0;
    top: 0;
}

// Page Preview
.pagePreview {
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.modal-header, .modal-body {
    padding: 1.5rem 1.5rem 1rem 1.5rem !important;
}

// Legal Warning
.legal-warning {
    --f: 10px; /* control the folded part*/
    --r: 15px; /* control the ribbon shape */
    --t: 20px; /* the top offset */
    
    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 0 10px var(--f) calc(10px + var(--r));
    clip-path: 
      polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
        var(--r) calc(50% - var(--f)/2));
    background: rgb(201, 9, 9);
    color: white;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    z-index: 1050;
    font-family: 'Patua One', cursive;
    font-size: 1.05rem;
  }

  .npaTooltip {
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    max-width: 500px;
  }

  .npaPreviewModal{
     .modal-content {
    background-color: transparent;
    border: none;
     }
     .modal-body {
        padding:0 !important;
         }
  }